/* eslint-disable no-mixed-operators */
import React, { useState, useEffect, useContext } from "react";
import { withRouter } from 'react-router-dom';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { DrawerWidth, DrawerWidthMinified } from 'fitbud/utils/constants';
import { FirebaseAuthContext } from "fitbud/providers/firebase-auth";
import { get } from "lodash";

const newDrawerState = (x, isMobile) => ({
  hidden: x === 1,
  collapsed: x === 2,
  minified: x === 2 && !isMobile,
  expanded: x === 3,
  drawerMode: isMobile ? 'temporary' : 'permanent',
  drawerWidth: x === 2 && !isMobile ? DrawerWidthMinified : DrawerWidth,
  drawerSpace: x === 1 || isMobile ? 0 : (x === 2 ? DrawerWidthMinified : DrawerWidth),
});

const xFontAwesome = [null, 'fas fa-bars', 'fas fa-arrow-left', 'fas fa-times'];
const newNavIcon = (x) => ({
  none: x === 1,
  hamburger: x === 2,
  back: x === 3,
  cross: x === 4,
  faicon: xFontAwesome[x - 1],
})

const defaultNavContext = {
  home: true,
  drawerState: newDrawerState(1),
  navIcon: newNavIcon(1),
}

export const NavContext = React.createContext(defaultNavContext);

const NavContextProvider = withRouter((props) => {
  const {location, children} = props;
  const { comp } = useContext(FirebaseAuthContext);
  const trial = comp && get(comp.data(), "contract.trial") ;
  const home = location.pathname === "/" && trial !== true;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [collapsed, setCollapsed] = useState(isMobile);
  useEffect(() => {
    if (isMobile) setCollapsed(true);
    else setCollapsed(false)
  }, [isMobile])
  const drawerState = newDrawerState((collapsed && 2 || 3), isMobile);
  const navIcon = newNavIcon((isMobile ? 2 : 1))

  return (<NavContext.Provider value={{
    home, drawerState, navIcon,
    toggleDrawer: () => setCollapsed(!collapsed),
    closeIfMobile: () => (isMobile && setCollapsed(true)),
    closeDrawer: () => setCollapsed(true),
    openDrawer: () => setCollapsed(false),
  }}>{children}</NavContext.Provider>)
})

export default NavContextProvider;
