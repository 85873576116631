import React, { useEffect, useState, useContext, useMemo, useRef, useLayoutEffect } from "react";
import _ from "lodash";
import clsx from "clsx";
import { connect } from "react-redux";
import { withSnackbar } from "notistack";
import planRdxFns from "fitbud/redux/plans";
import appRdxFns from "fitbud/redux/app";
import ReorderDialog from "fitbud/components/reorderDialog";
import firebase from "fitbud/firebase";
import { makeStyles } from "@material-ui/core/styles";
import { AccessContext } from 'fitbud/providers/access-provider';
import { PlanContext } from "./planProvider";
import * as Sentry from "@sentry/browser";
import {
  List,
  ListItem,
  ListItemText,
  Typography,
  LinearProgress,
  IconButton,
  ListItemAvatar,
  Tooltip,
  Badge,
} from "@material-ui/core";
import NoPlans from "fitbud/images/no_plans.svg";
import ReorderIcon from '@material-ui/icons/SwapVert';
import Refresh from "@material-ui/icons/Refresh";
import { NavIcon } from "fitbud/partials/appBar";
import { parsePacks, orderPacks, getActiveStatus } from './helper';
import Img from './img';
import { FilterIcon } from 'fitbud/icons/filterIcon';
import CatalogFilter from 'fitbud/components/catalog-filter';
import { isFilterExists } from 'fitbud/components/catalog/util';
import { LineFilterIcon } from 'fitbud/icons/lineFilterIcon';
import { FirebaseAuthContext } from "fitbud/providers/firebase-auth";

export const usePlanStyles = makeStyles(() => ({
  container:{
    borderBottom: '2px solid #D8DCE0',
    position: 'relative',
    background: '#F2F4F7',
    '& .actionBtnsContainer':{
      display:'flex',
      marginRight:'-8px',
      '& .closeSvg':{
        fill:"#37404d"//mui icon, using same color as custom icon
      }
    },
    '& .loading': {
      position: 'absolute',
      bottom: -2,
      left: 0,
      width: '100%',
      height: 2,
    },
    "& .refreshIcon":{
      // display:"none"
    },
    "&:hover":{
      // "& .refreshIcon":{
      //   display:"flex"
      // }
    }
  },
  root: {
    minHeight: "80px",
    "& .MuiListItemText-primary": {
      marginBottom: "2px",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      fontWeight: "600"
    },
    "& .MuiListItemText-secondary": {
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    },
    "&.Mui-selected": {
      backgroundColor: "#F6F9FE",
      "&:hover": {
        backgroundColor: "#F6F9FE"
      }
    },
    "&:hover": {
      backgroundColor: "#F6F9FE"
    }
  },
  refreshIconButton:{
    padding:8,
    // margin:"0px 2px" //margin horizontally for hover effect not touches with searchInput
  },
  avatarImage: {
    width: "70px!important",
    height: "50px!important",
    borderRadius: "10px",
    marginRight: "16px"
  },
}));

export const SeconderyText = ({ftCalls, data, id, getPackAccess}) => {
  const {type, one_to_one, group_class, hasAutoSch, accessNA, hasClasses, hasServices, hasIAP, hasPlay} = useMemo(() => {
    const {accessNA, hasClasses, hasServices} = getPackAccess ? getPackAccess(id) : {accessNA: true};
    const add_on = data.type === 'add_on';
    const one_to_one = add_on && data.add_on_type === 'one_to_one';
    const group_class = add_on && data.add_on_type === 'group_class';
    const out = {
      type: 'Membership', add_on, one_to_one, group_class,
      hasAutoSch: _.get(data, 'automation.enabled', false) || _.get(data, 'automation.locked', false),
      accessNA, hasClasses, hasServices, hasIAP: false, hasPlay: false,
    };
    if (data.type === 'add_on') {
      switch (data.add_on_type) {
        case 'one_to_one':
        case 'group_class':
          out.type = 'Sessions Pack';
          break;
        default:
          out.type = 'Add-On';
          break;
      }
    }
    out.hasIAP = _.chain(data)
      .get('price_opts').findIndex(x => (getActiveStatus(x, "active_ios") && !!x.iap_id && x.method_ios === 'iap'))
      .value() >= 0;
    out.hasPlay = _.chain(data)
      .get('price_opts').findIndex(x => (getActiveStatus(x, "active_android") && !!x.play_product_id))
      .value() >= 0;
    return out;
  }, [data, id, getPackAccess]);

  if (!id || !getPackAccess || accessNA)
    return <span className="font_13_500 text-dark-grey">{type}</span> ;

  return (
    <>
      <span className="font_13_500 text-dark-grey">{type}</span> 
      {!!hasAutoSch && <span className='ml-6'>•</span>}
      {!!hasAutoSch && <Tooltip title='Includes Automatation'><i className='fas fa-bolt ml-6'/></Tooltip>}
      {!!ftCalls && (!!hasClasses || !!hasServices || !!group_class || !!one_to_one) && <span className='ml-6'>•</span>}
      {!!ftCalls && !!hasClasses && <Tooltip title='Includes access to Classes'><i className='fas fa-users ml-6'/></Tooltip>}
      {!!ftCalls && !hasClasses && group_class && <Tooltip title='Lacks access to Classes'><i className='fas fa-users-slash text-D73717 ml-6'/></Tooltip>}
      {!!ftCalls && !!hasServices && <Tooltip title='Includes access to Sessions'><i className='fas fa-video ml-6'/></Tooltip>}
      {!hasServices && one_to_one && <Tooltip title='Lacks access to Sessions'><i className='fas fa-video-slash text-D73717 ml-6'/></Tooltip>}
      {(!!hasIAP || !!hasPlay) && <span className='ml-6'>•</span>}
      {!!hasIAP && <Tooltip title='Includes Apple In-App purchases'><i className='fab fa-apple ml-6'/></Tooltip>}
      {!!hasPlay && <Tooltip title='Includes Google Play subscriptions'><i className='fab fa-google-play ml-6'/></Tooltip>}
    </>
  )
}

const badgeanchor = {vertical: 'bottom', horizontal:'right'};
const badgeStyles = makeStyles({
  hidden: { backgroundColor: "#FD8847" },
  active: { backgroundColor: "#05B715" },
  inactive: { backgroundColor: "#D73717" },
  common: {
    right: 4, bottom: 4,
    boxShadow:'0px 0px 0px 3px #fff',
    width: 8, height: 8,
    borderRadius: '100%',
  }
});
const StatusBadge = ({data, children}) => {
  const styles = badgeStyles();
  const style = useMemo(() => {
    if (!data) return {};
    const hasActive = getActiveStatus(data || {}, "active");
    const status = hasActive ? 'active' : (data.hidden ? 'hidden' : 'inactive');
    return {badge: styles[status] + ' ' + styles.common};
  }, [data, styles]);
  return (
    <Badge classes={style}
    badgeContent=' ' variant='dot'
    anchorOrigin={badgeanchor}
    children={children}/>
  );
}

const PlanList = props => {
  const classes = usePlanStyles();
  const { cid, hasVidCall, parsePacksForIapIds, hasAppleIAP, hasPlayIAP, paymentGateWay } = useContext(PlanContext);
  const { getPackAccess } = useContext(AccessContext);
  const [openReorder, setReorderDialog] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isFilterOpen, setFilterOpen] = useState(false);
  const [filterState, setFilterState] = useState(null);
  const [filterDocs, setFilterDocs] = useState([]);
  const { docs, onSelect, selected, showLoader, hideLoader } = props;
  const appliedFilter =  isFilterExists(filterState);
  const initialFetched = useRef(false);
  const {comp} = useContext(FirebaseAuthContext)
  const company = comp ? comp.data() : {};
  const isGroupClass = Boolean(_.get(company, "features.group_class.enabled", false));

  let onClick = null;
  if (!!onSelect) {
    onClick = doc => {
      onSelect(doc._id);
    };
  }


  useEffect(() => {
    if (!!docs) return;
    fetchList();
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  useLayoutEffect(()=>{
    if(!initialFetched.current && (docs && docs.length)){
      initialFetched.current = true;
      applyFilter({plan:{status:['active']}})
    }
  },[docs,initialFetched ])

  //Filter Effects ::
  useLayoutEffect(()=>{
    const _appliedFilters = _.get(filterState, 'plan', {});
    //filter state logic:
    /*
    possible values of applied filters be like :
    status:['active', 'in_active'], mode:[one_time, "subscription"]
    scheduling:["on", "off"], video_calling:["yes", "no"],
    payment:[stripe, paypal,apple-in-app, google-play ]
    */
    const _allDocs = [...(docs || [])];
    const _filterDocs = [];
    Object.keys(_appliedFilters).forEach((key, index) => {
      let _arr = [];
      const values = _appliedFilters[key] || [];
      if (!!values.length) {
        values.forEach((value) => {
          if(key === "type"){
            if (value === 'regular') {
              let arr = _.filter(_allDocs, (plan) => !plan.data.type || plan.data.type === 'regular'); //active filter will return active and hidden plan
              _arr = [..._arr, ...arr];
            } else if (value === 'add_on') {
              let arr = _.filter(_allDocs, (plan) => plan.data.type === 'add_on'); //active filter will return active and hidden plan
              _arr = [..._arr, ...arr];
            }
          }
          if (key === 'status') {
            if (value === 'active') {
              let arr = _.filter(_allDocs, (plan) => !!getActiveStatus(plan.data, 'active') || !!plan.data.hidden); //active filter will return active and hidden plan
              _arr = [..._arr, ...arr];
            } else if (value === 'in_active') {
              let arr = _.filter(_allDocs, (plan) => !getActiveStatus(plan.data, 'active') && !plan.data.hidden);
              _arr = [..._arr, ...arr];
            } else if (value === 'hidden') {
              let arr = _.filter(_allDocs, (plan) => !!plan.data.hidden);
              _arr = [..._arr, ...arr];
            }
          }
          if (key === 'scheduling') {
            if (value === 'on') {
              let arr = _.filter(_allDocs, (plan) => !!_.get(plan, "data.automation.enabled"));
              _arr = [..._arr, ...arr];
            } else if (value === 'off') {
              let arr = _.filter(_allDocs, (plan) => !_.get(plan, "data.automation.enabled"));
              _arr = [..._arr, ...arr];
            }
          }
          if(key === "mode"){
            if(value === "subscription"){
              let arr = _.filter(_allDocs, (plan)=>{
                const hasSubs = _.chain(plan.data).get("price_opts")
                .findIndex((x)=>x.mode === "subscription")
                .value() >=0;
                return hasSubs;
              })
              _arr = [..._arr, ...arr];
            } 
            if(value === "one_time"){
              let arr = _.filter(_allDocs, (plan)=>{
                const hasSubs = _.chain(plan.data).get("price_opts")
                .findIndex((x)=>x.mode === "one_time")
                .value() >=0;
                return hasSubs;
              })
              _arr = [..._arr, ...arr];
            } 
          }
          if (key === 'payment') {
            if (value === 'google_play') {
              let arr = _.filter(_allDocs, (plan) => {
                const hasPlay =
                  _.chain(plan.data)
                    .get('price_opts')
                    .findIndex((x) => getActiveStatus(x, "active_android") && !!x.play_product_id)
                    .value() >= 0;
                return hasPlay;
              });
              _arr = [..._arr, ...arr];
            }
            if (value === 'apple_in_app') {
              let arr = _.filter(_allDocs, (plan) => {
                const hasIAP =
                  _.chain(plan.data)
                    .get('price_opts')
                    .findIndex((x) => getActiveStatus(x, "active_ios") && !!x.iap_id && x.method_ios === 'iap')
                    .value() >= 0;
                return hasIAP;
              });
              _arr = [..._arr, ...arr];
            }
            if(value === "others"){
              let arr = _.filter(_allDocs, (plan) => {
                const hasPlay =
                  _.chain(plan.data)
                    .get('price_opts')
                    .findIndex((x) => getActiveStatus(x, "active_android") && !!x.play_product_id)
                    .value() >= 0;

                const hasIAP =
                  _.chain(plan.data)
                    .get('price_opts')
                    .findIndex((x) => getActiveStatus(x, "active_ios") && !!x.iap_id && x.method_ios === 'iap')
                    .value() >= 0;

                 return !hasPlay && !hasIAP
              });

              _arr = [..._arr, ...arr];

            }
          }
        });
      }
      if (!!_arr.length) {
        _filterDocs.push(_.uniqBy(_arr, '_id'));
      }
    });
    let _out = _.intersectionBy(..._filterDocs, '_id');
    setFilterDocs(orderPacks(_out));
  },[docs, filterState])

  const _filterOptions = useMemo(()=>{
    return {
      hasAppleIAP,hasPlayIAP, paymentGateWay
    }
  },[hasAppleIAP,hasPlayIAP, paymentGateWay ])

  const isReorderOption = useMemo(()=>{
    if(loading || !docs || !docs.length) return false;
    //check for active elements is more than two
    let activePacs =0;
    for (let doc of docs){
      if(getActiveStatus(doc.data || {}, "active"))  activePacs++;
      if(activePacs >=2) break;
    }
    return  activePacs >=2;
  },[docs, loading])

  const fetchList = () => {
    if (loading) return;
    setLoading(true);
    firebase.firestore().collection(`companies/${cid}/packs`).get()
      .then((docs) => {
        props.planOps.set(parsePacks(docs, isGroupClass), 0, true);
        parsePacksForIapIds(docs);
        setLoading(false);
      }).catch((err) => {
        Sentry.captureException(err);
        setLoading(false);
      });
  };

  const toggleReorder = () => {
    if (openReorder) return setReorderDialog(null);
    let reorderData = [];
    (docs || []).forEach((list)=>{
      if(getActiveStatus(list.data || {}, "active")){
        const _out = {
          name: list.data.ref_name, id: list._id
        }
        reorderData.push(_out);
      }
    })
    setReorderDialog(reorderData)
    // setReorderDialog(docs.map((list, i) => {
    //   if (list) return { name: (list.data.active ? '🟢 ' : '🔴 ') + list.data.ref_name, id: list._id };
    //   else return {};
    // }));
  };

  const  applyFilter = (filter) =>{
    setFilterState(filter);
  }


  const onSaveReorderPlan = async (packs) =>{
    const batch = firebase.firestore().batch();
    const activeLength = packs.length;
    const remainingPacks = docs.slice(activeLength);     //get remaining docs:
    packs.forEach((p,index)=>{
      const ref = firebase.firestore().doc(`companies/${cid}/packs/${p.id}`);
      batch.update(ref,{index})
    })
    //increase remaining docs incrementally by last active Index:
    let restPackIndex = activeLength;
    remainingPacks.forEach((p)=>{
      const ref = firebase.firestore().doc(`companies/${cid}/packs/${p._id}`);
      batch.update(ref, {index : restPackIndex++ })
    })

    try{
      showLoader();
      await batch.commit();
      fetchList();
    }catch(err){
      Sentry.captureException(err);

    }
    hideLoader();
  }

  const getDocCounts = () =>{
    if(loading) return "";
    const _count = appliedFilter ? filterDocs && filterDocs.length : docs && docs.length ;
    return _count ? `(${_count})` : "";
  }
  const _docs = appliedFilter ? filterDocs :docs;


  return (
    <div className="h-100 d-flex flex-column">
      <div className={clsx(classes.container, "px-20")}>
        <div className={clsx("d-flex bg-light-grey align-items-center")} style={{ minHeight: "56px" }}>
          <NavIcon />
          <Typography variant='h3' className='flex-grow-1 text-capitalize'>Plans <span className="font-500">{getDocCounts()}</span></Typography>
          <div className="actionBtnsContainer">
          {isReorderOption && <Tooltip title="Reorder - Plans will show in the same order you decide." placement="start-top">
            <IconButton onClick={toggleReorder}><ReorderIcon/></IconButton>
          </Tooltip>}
          <Tooltip title="Refresh list to see the new changes if any." placement="start-top">
            <IconButton onClick={fetchList} className={ clsx("refreshIcon", classes.refreshIconButton ) }><Refresh /></IconButton>
          </Tooltip>
          <IconButton onClick={()=>setFilterOpen(true)}>
          <Badge color="primary" badgeContent={appliedFilter}>
            {appliedFilter?<FilterIcon />:<LineFilterIcon />}
          </Badge>
          </IconButton>
          </div>
          {!!loading && <LinearProgress className="loading" />}
        </div>
      </div>
      {(!_docs || !_docs.length) && (
        <div
          className="text-center d-flex align-items-center justify-content-center flex-column position-absolute"
          style={{
            top: "50%",
            left: "50%",
            transform: "translate(-50%,-50%)"
          }}
        >
          <div className="fmt-30 fmb-30 ">
            <img alt="noitem" src={NoPlans} />
          </div>
          <Typography variant="h2" className="fmb-10">
            No items found
          </Typography>
          <Typography variant="body2">
            Click on add to create new item.
          </Typography>
        </div>
      )}
      <List disablePadding className="position-relative overflow-auto">
        {_docs && _docs.map((doc, i) => (
          <ListItem
            key={i}
            className="fpy-10 fpx-20"
            button
            selected={selected === doc._id}
            onClick={() => onClick(doc)}
            id={`plan_${doc._id}`}
            classes={{
              root: classes.root,
              // root: listClasses.root,
              // selected: listClasses.selected
            }}
          >
            <ListItemAvatar className='mr-3'>
              <StatusBadge data={doc.data}>
                <Img name={doc.data.title} src={doc.data.image} size={360} width={70} aspect={1.5} className='rounded rounded-10'/>
              </StatusBadge>
            </ListItemAvatar>
            <ListItemText
              primary={doc.data.ref_name}
              secondary={<SeconderyText ftCalls={hasVidCall} data={doc.data} id={doc._id} getPackAccess={getPackAccess} />} />
          </ListItem>
        ))}
      </List>

      {openReorder && (
        <ReorderDialog
          open
          listData={openReorder}
          onClose={toggleReorder}
          onSubmit={onSaveReorderPlan}
          title="Reorder Plans"
          paperClass="width-400 height-60"
        />
      )}
      {isFilterOpen && <CatalogFilter
       filters={filterState}
       keyName="plan"
       open={true}
       onSave={applyFilter}
       handleClose={()=>setFilterOpen(false)}
       filterConfig={_filterOptions}
      />}
    </div>
  );
};

const mapStateToProps = (s, op) => {
  return { ...s[op.keyName] };
};

const mapDispatchToProps = d => {
  const { showLoader, hideLoader } = appRdxFns(d);
  const planOps = planRdxFns(d);
  return {
    planOps, showLoader, hideLoader
  };
};

export default withSnackbar(
  connect(mapStateToProps, mapDispatchToProps)(PlanList)
);
